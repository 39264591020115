<template>
  <div class="last">
    <h1>{{ text }}</h1>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data: function() {
    return {
      text: "xrp",
      connection: null
    }
  },
  methods: {
    connectToBitbank: function() {
      var _self = this

      this.connection = new WebSocket("wss://stream.bitbank.cc/socket.io/?EIO=3&transport=websocket")

      this.connection.onmessage = function(event) {
        var data = event.data

        if (data.startsWith('42')) {
          var j = JSON.parse(data.slice(13, data.length - 1))
          _self.text = j.message.data.last
        }
      }

      this.connection.onopen = function() {
        // _self.connection.send("42[\"join-room\",\"ticker_btc_jpy\"]")
        _self.connection.send("42[\"join-room\",\"ticker_xrp_jpy\"]")
        // _self.connection.send("42[\"join-room\",\"ticker_eth_jpy\"]")
      }

      this.connection.onclose = () => {
          _self.connectToBitbank()
      }

      this.connection.onerror = (event) => {
          console.log("WebSocket error observed:", event)
          _self.text = "Error"
      }
    }
  },
  created: function() {
    this.connectToBitbank()    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h1 {
  font-size: 8em;
}

.last {
  display: flex;
  justify-content: center;
  align-items: center;
  height:100vh;
}

</style>
